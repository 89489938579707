/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const userData: any = useAuth();
  // const tools = userData?.auth?.client_tools?.map(
  //   (obj: any) => Object.keys(obj)[0]
  // );
  const tools = userData?.auth?.client_tools ? Object.keys(userData.auth.client_tools) : [];

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            CLIENT AREA
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/subscriptions/list"
        title="Subscriptions"
        fontIcon="bi-archive"
        icon="dollar"
      />
      <SidebarMenuItem
        to="/subscriptions/bills"
        title="Bills"
        fontIcon="bi-archive"
        icon="finance-calculator"
      />
      <SidebarMenuItem
        to="/invoicing/invoices"
        title="Invoices"
        icon="credit-cart"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/invoicing/receipts"
        title="Receipts"
        icon="burger-menu-4"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/invoicing/credit"
        title="Credit Notes"
        icon="two-credit-cart"
        fontIcon="bi-person"
      />
      <SidebarMenuItemWithSub
        to="/statements"
        title="Statements"
        icon="burger-menu"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="/statements/billing"
          title="Billing Statement"
          icon="burger-menu-1"
          fontIcon="bi-person"
        />

        <SidebarMenuItem
          to="/statements/customer"
          title="Customer Statement"
          icon="burger-menu-2"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/statements/outstanding"
          title="Outstanding Statement"
          icon="burger-menu-3"
          fontIcon="bi-person"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to="/customer"
        title="Workflows"
        fontIcon="bi-sticky"
        icon="question"
      >
        <SidebarMenuItem
          to="/customer/service"
          title="Service Ticket"
          icon="user-edit"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/customer/training"
          title="Training Ticket"
          icon="profile-user"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/customer/trouble"
          title="Trouble Ticket"
          icon="profile-circle"
          fontIcon="bi-person"
        />
      </SidebarMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Tools
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/people/contacts"
        title="Contacts"
        icon="people"
        fontIcon="bi-person"
      />
      {tools.includes("Tracking Portal") ? (
        <SidebarMenuItem
          to="/tracking/portal"
          title="Tracking Portal"
          fontIcon="bi-sticky"
          icon="delivery-geolocation"
        />
      ) : null}
      {tools.includes("BI Analytics") ? (
        <SidebarMenuItem
          to="/bi/analytics"
          title="BI Analytics"
          fontIcon="bi-sticky"
          icon="chart-line-star"
        />
      ) : null}

      {tools.includes("Incident Management Alerts") ? (
        <SidebarMenuItem //incident
          to="/tools/incident/management"
          title="Incident Management"
          icon="notification-on"
          fontIcon="bi-person"
        />
      ) : null}

      {tools.includes("Sensor Dashboard") ? (
        <SidebarMenuItem //sensor dashboard
          to="/tools/sensor/sensordashboard"
          title="Sensor Dashboard"
          icon="scan-barcode"
          fontIcon="bi-person"
        />
      ) : null}
      {tools.includes("Logistics Uploader") ? (
        <SidebarMenuItem // logistics
          to="/tools/logistics/uploader"
          title="Logistics Uploader"
          icon="parcel"
          fontIcon="bi-person"
        />
      ) : null}

      {tools.includes("Geofence Monitoring") ? (
        <SidebarMenuItem // geofance
          to="/tools/geofence/monitoring"
          title="Geofence Monitoring"
          icon="map"
          fontIcon="bi-person"
        />
      ) : null}
    </>
  );
};

export { SidebarMenuMain };
