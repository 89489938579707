import {FC, useState} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {checkIsActive, KTIcon} from '../../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  textColor?: string // Add textColor prop
  bulletColor?: string // Add bulletColor prop
  iconColor?: string // Add fontColor prop
}

const MenuItem: FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  textColor, // Receive textColor prop
  bulletColor, // Receive bulletColor prop
  iconColor, // Receive fontColor prop
}) => {
  const {pathname} = useLocation()
  // const [hovered, setIsHovered] = useState(true);
  return (
    <div className='menu-item '>
      <Link
        className={clsx('menu-link  py-3', {
          'active menu-here': checkIsActive(pathname, to),
        })}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot' style={{backgroundColor: bulletColor}}></span>
            {/* Apply custom bullet color */}
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2 text-dark' />
            {/* Apply custom icon color */}
          </span>
        )}
        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)} style={{color: iconColor}} />
            {/* Apply custom icon color */}
          </span>
        )}
        <span className={clsx('menu-title')} style={{color: textColor}}>
          {title}
        </span>
        {/* Apply custom text color */}
        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export {MenuItem}
