import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
export const ORGANIZATION_KEY = "organization_key";
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
      // return {api_token: '1|ksfjefnsdfsdffdfd', refreshToken: '2|alndfjsfs9sfsdf'}
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

// ============= SET THE SELECTED ACCOUNT =============
export const selectOrganization = (org_id: string) => {
  if (!org_id) return;
  localStorage.setItem(ORGANIZATION_KEY, org_id);
};

export const getSelectedOrganization = () => {
  let auth: any = getAuth();
  return auth?.organizations?.find(
    (oo: any) =>
      parseInt(oo.organization_id) ===
      parseInt(localStorage.getItem(ORGANIZATION_KEY) as string)
  );
};

export const listOrganizations = () => {
  let auth: any = getAuth();
  return auth?.organizations;
};

const setAuth = (auth: any) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);

    //Lets work with organization key
    let org_id = auth?.organizations?.length
      ? auth?.organizations[0].organization_id
      : 0;
    let stored_org_id: any = localStorage.getItem(ORGANIZATION_KEY);

    if (
      auth?.organizations?.some(
        (oo: any) => parseInt(oo.organization_id) === parseInt(stored_org_id)
      )
    ) {
      //leave it alone
    } else {
      localStorage.setItem(ORGANIZATION_KEY, org_id);
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  // axios.defaults.headers['Content-type'] = 'application/json'

  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
