import axios from "axios";
import { AuthModel, UserModel } from "./_models";
import { BASE_URL } from "../../profile/components/constants";
import { getSelectedOrganization } from "./AuthHelpers";

const API_URL = process.env.REACT_APP_API_URL;

// export const GET_USER_BY_ACCESSTOKEN_URL = `${BASE_URL}verify_token`
// export const LOGIN_URL = `${BASE_URL}login`

// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token` /
// export const LOGIN_URL = `${API_URL}/login`
// export const REGISTER_URL = `${API_URL}/register`
// export const REQUEST_PASSWORD_URL = `${BASE_URL}forget-password`
// export const REQUEST_RESET_PASSWORD_URL = `${BASE_URL}reset-password`

export const GET_USER_BY_ACCESSTOKEN_URL = `${BASE_URL}auth/verify-token`;
export const LOGIN_URL = `${BASE_URL}auth/login`;
export const AUTH_VERIFY_2FA = `${BASE_URL}auth/verify`;
// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token` /
// export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${BASE_URL}auth/forgot-password`;
export const REQUEST_RESET_PASSWORD_URL = `${BASE_URL}auth/reset-password`;
// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    is_client: 1,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPassword(
  email: string,
  password?: string,
  confirm_password?: string,
  token?: string
) {
  return axios.post<{ result: boolean }>(REQUEST_RESET_PASSWORD_URL, {
    email,
    password,
    token,
    password_confirmation: confirm_password,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}
let organizationData = getSelectedOrganization();

axios.interceptors.request.use(
  (config: any) => {
    // You can modify the request config here
    config.headers["x-organization-id"] = organizationData?.organization_id;
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);
export const httpGet = (path: string, headers?: any) => {
  if (!headers) {
    return axios.get(`${BASE_URL}${path}`);
  }
  return axios.get(`${BASE_URL}${path}`, {
    headers,
  });
};

export const httpPostWithFile = (path: any, data: any) => {
  let formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return axios.post(`${BASE_URL}${path}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const httpPost = (path: any, data: any, withFile?: boolean) => {
  //check if we use file parameter or not

  if (withFile) {
    return axios.post(`${BASE_URL}${path}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return axios.post(`${BASE_URL}${path}`, data);
};

export const httpDelete = (path: any) => {
  return axios.delete(`${BASE_URL}${path}`);
};

export const httpPut = (path: any, data: any, withFile?: boolean) => {
  if (withFile) {
    return axios.put(`${BASE_URL}${path}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return axios.put(`${BASE_URL}${path}`, data);
};
