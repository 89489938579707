import { FC, useState } from "react";
import {
  getSelectedOrganization,
  listOrganizations,
  selectOrganization,
} from "../../../../app/modules/auth";

export const AccountSwap: FC = () => {
  const organizations = listOrganizations();
  return (
    <div className="d-flex flex-column">
      <a href="#" className="menu-link px-5 text-hover-primary">
        Switch Organization
      </a>

      {organizations?.map((org: any) => (
        <span
          className="menu-link px-5 text-hover-primary ms-6 fw-lighter"
          key={org.organization_id}
          onClick={() => {
            //refresh the entire screen on swap
            selectOrganization(org.organization_id);
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }}
        >
          {org.organization_name}
        </span>
      ))}
    </div>
  );
};
